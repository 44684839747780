import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Hero from '../components/Shared/Hero'
import Search from '../components/Shared/Search'
import Space from '../components/Shared/Space'
import Event from '../components/Shared/Event'
import Summer from '../components/Shared/Summer'
import Logement from '../components/Shared/Logement'
import Push from '../components/Shared/Push'
import Questionnaires from '../components/Shared/Questionnaires'
import * as styles from './styles/residence.module.scss'
import * as stylesStay from './styles/single-stay.module.scss'
import { Helmet } from 'react-helmet'
import Instagram from '../components/Shared/Instagram'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import { useLocation } from '@reach/router'
import BlockExperience from '../components/Shared/BlockExperience'
import BlockStep from '../components/Shared/BlockStep'
import Deal from '../components/Shared/Deal'
import ServicesStay from '../components/Shared/ServiceStay'
import BlockSeoText from '../components/Shared/BlockSeoText'
import Breadcrumb from '../components/Core/Breadcrumb'
import { useTranslation } from '../hooks/useTranslation'
import useLinkLocalized from '../hooks/useLinkLocalized'
import KLink from '../components/Core/KLink'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import Modal from '../components/Core/Modal'
import ModalGeneral from '../components/Shared/ModalGeneralContent'

export const Head = head('umbracoStayPage')

export default function SingleStay(props: any) {
  const { data, pageContext } = props
  const page = data.umbracoStayPage
  const popinGeneralVisible = page.popinGeneralVisible
  const instagramData = data.umbracoInstagram
  const pageTitle = page?.metaTitle
    ? page?.metaTitle
    : page?.title
    ? page?.title.replace(/\n/g, '')
    : 'Stay'
  const listingResidence = data.umbracoListingResidencePage
  const location = useLocation()
  const [showGeneral, setShowGeneral] = useState<boolean>(false)
  // console.log(pageContext)
  // console.log(page)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
      if (
        popinGeneralVisible &&
        !sessionStorage.getItem('single_stay_' + page.id + '_already_shown')
      ) {
        setTimeout(() => {
          setShowGeneral(true)
        }, 2000)
      }
    }
  }, [])

  const hideModal = () => {
    setShowGeneral(false)
    sessionStorage.setItem('single_stay_' + page.id + '_already_shown', 'true')
  }

  return page ? (
    <>
      <Helmet>
        <body className="single-residence single-stay" />
      </Helmet>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'RealEstateAgent',
            name: page.title,
            image:
              page.imageCover && page.imageCover.childImageSharp
                ? page.imageCover.childImageSharp.gatsbyImageData.images
                    .fallback.src
                : '',
            '@id': page.title,
            url: location?.href,
            telephone: '',
            address: {
              '@type': 'PostalAddress',
              streetAddress: page.streetAddress,
              addressLocality: page.addressLocality,
              postalCode: page.postalCode,
              addressCountry: page.addressCountry,
            },
            geo: {
              longitude: page.longitude,
              latitude: page.latitude,
            },
          }),
        }}
      ></script>
      <Layout className={`${styles.residenceDetail} ${stylesStay.singleStay}`}>
        <Hero
          title={page.title.replace(/\n/g, '<br>')}
          image={page.imageCover}
          image-mobile={page.imageCoverMobile}
          video={page.videoCover}
        >
          {/* <Search /> */}
          <DecoBigIllus className="deco" />
        </Hero>
        {/* <KLink
          to="/popins/votre-candidature-moyen-sejour"
          label="application modal"
        ></KLink> */}
        {page.items &&
          page.items.map((homeBlock: any, index: number) => {
            if (homeBlock.content) {
              const blockContent = homeBlock.content
              switch (blockContent.contentTypeAlias) {
                case 'residencePush':
                  return (
                    <Summer
                      data={{
                        title: blockContent.title,
                        paragraph: blockContent.subtitle,
                        link: blockContent.link
                          ? blockContent.link[0]
                          : blockContent.links
                          ? blockContent.links[0]
                          : {
                              name: 'Contacter la residence',
                              url: '/',
                            },
                        linkEvent: blockContent.linksEvents
                          ? blockContent.linksEvents[0]
                          : null,
                        cta: blockContent.link
                          ? blockContent.link[0]
                          : blockContent.virtualVisit
                          ? {
                              title: 'VISITE VISTUELLE',
                              path: '',
                            }
                          : undefined,
                        position: page.title,
                      }}
                      blockClassName={`summer-push ${
                        index === 0 ? 'concept-push' : ''
                      }`}
                      illustration={blockContent.illustration}
                      bg={blockContent.backgroundColor}
                      titleColor={blockContent.titleColor}
                      key={`s-${index}`}
                      className="summer-push"
                    />
                  )
                case 'blockAccomodations':
                  return (
                    <Logement
                      className="logement"
                      data={blockContent}
                      key={`ba-${index}`}
                      listingResidence={listingResidence}
                      pageType={pageContext.pageIntl.pageTemplate}
                    />
                  )
                case 'blockStayIncludedService':
                  return (
                    <ServicesStay data={blockContent} key={`bis-${index}`} />
                  )
                case 'blockConcept1':
                  return (
                    <Space
                      data={blockContent}
                      key={`bc-${index}`}
                      className="space"
                    />
                  )
                case 'blockPush':
                  return <Push data={blockContent} key={`bp-${index}`} />
                case 'blockNewEvents':
                  return <Event data={blockContent} key={`be-${index}`} />
                case 'blockFAQ':
                  return (
                    <Questionnaires data={blockContent} key={`fa-${index}`} />
                  )
                case 'blockExperience':
                  return (
                    <BlockExperience
                      reverse
                      data={blockContent}
                      key={`bex-${index}`}
                    />
                  )
                case 'blockStep':
                  return <BlockStep data={blockContent} key={`bst-${index}`} />
                case 'blockDeal':
                  return blockContent.available ? (
                    <Deal data={blockContent} key={`bd-${index}`} />
                  ) : (
                    <></>
                  )
              }
            }

            return null
          })}

        {page.bottomSEOText && <BlockSeoText content={page.bottomSEOText} />}
        <Instagram data={instagramData} />

        <Breadcrumb
          data={[
            {
              title: page.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />
        <div id="modal-single-stay">
          { popinGeneralVisible && page.popinToDisplay && (
            <Modal
              data={page.popinToDisplay}
              visible={showGeneral}
              onClose={hideModal}
              width={'1018px'}
              secondaryBg="FFFFFF"
              size="lg"
              general
              darkClose
            >
              <ModalGeneral data={page.popinToDisplay} />
            </Modal>
          )}
        </div>
      </Layout>
    </>
  ) : (
    <></>
  )
}

export const query = graphql`
  query ($name: String, $lang: String) {
    umbracoStayPage(name: { eq: $name }) {
      id
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      bottomSEOText
      _url
      _urls {
        fr
        en_us
      }
      title
      shortName
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      popinGeneralVisible
      popinToDisplay {
        _id
        name
        popinType
        subtitle
        stayTitle
        staySubtitle
        isActive
        popinGTMEventName
        cta {
          name
          type
          url
        }
        _urls {
          en_us
          fr
        }
        colorCombination
        contactPhoneNumber
        contentTypeAlias
        title
        _url
      }
      items {
        content {
          title
          subtitle
          contentTypeAlias
          titleColor
          backgroundColor
          virtualVisit
          textColor
          authorName
          authorPosition
          reversePlacement
          available
          link {
            url
            name
            type
          }
          links {
            url
            name
            type
          }
          linksEvents
          illustration {
            src
          }
          experienceLink {
            name
            url
          }
          experienceLinkEvent
          stepLink {
            name
            url
            target
          }
          stepLinkEvent
          virtualVisit
          virtualVisitUrl
          virtualVisitButtonLabel
          backgroundVimeoId
          stepContent
          experienceItems
          secondShortDescription
          titlePaidService
          titleIncludedService
          stayItemsIncludedServices {
            name
            title
            subtitle
          }
          stayItemsPaidServices {
            name
            title
            subtitle
          }
          itemsPush {
            name
            title
            subtitle
            sortOrder
            titleColor
            backgroundColor
            contentTypeAlias
            link {
              url
              type
              name
            }
            linkEvent
            illustration {
              src
            }
            pushStyle
          }
          shortDescription
          itemsFaq {
            name
            answer
            question
            contentTypeAlias
          }
          itemsAccomodation {
            name
            title
            subtitle
            priceFrom
            contentTypeAlias
            shortDescription
            accomodationTag
            accomodationType {
              accomodationTypeTitle
            }
            thumbnailImages {
              gatsbyImageData
              media {
                alt
                name
                _url
              }
            }
            details {
              title
              boTitle
              subtitle
            }
            accommodationDetailModalDescription
            accommodationDetailModal {
              content {
                title
                description
              }
            }
          }
          linkFaq {
            url
            name
            type
          }
          spaces {
            title
            subtitle
            name
            mainColor
            medias {
              src
              gatsbyImageData(width: 880, quality: 90)
            }
          }
          eventsList {
            title
            subtitle
            bg
            thumbnail {
              media {
                alt
                _url
              }
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            category {
              color
              title
            }
            price
          }
          illustration {
            src
          }
          linkFaq {
            name
            url
          }
          dealsItems {
            thumbnail {
              src
              gatsbyImageData(width: 880, quality: 90)
            }
            backgroundVimeoId
            title
            description
            link {
              url
              name
            }
          }
        }
      }
    }
    umbracoListingResidencePage(lang: { eq: $lang }) {
      _url
      _urls {
        fr
        en_us
      }
    }
    umbracoInstagram(lang: { eq: $lang }) {
      title
      hashtag
      instagramHandle
      instagramLinkButton {
        url
        name
        type
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
